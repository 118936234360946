import { Component, Input, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { strings as af } from 'ngx-timeago/language-strings/af';
import { strings as ar } from 'ngx-timeago/language-strings/ar';
import { strings as az } from 'ngx-timeago/language-strings/az';
import { strings as bg } from 'ngx-timeago/language-strings/bg';
import { strings as bs } from 'ngx-timeago/language-strings/bs';
import { strings as ca } from 'ngx-timeago/language-strings/ca';
import { strings as cs } from 'ngx-timeago/language-strings/cs';
import { strings as cy } from 'ngx-timeago/language-strings/cy';
import { strings as da } from 'ngx-timeago/language-strings/da';
import { strings as de } from 'ngx-timeago/language-strings/de';
import { strings as dv } from 'ngx-timeago/language-strings/dv';
import { strings as el } from 'ngx-timeago/language-strings/el';
import { strings as en } from 'ngx-timeago/language-strings/en';
import { strings as es } from 'ngx-timeago/language-strings/es';
import { strings as et } from 'ngx-timeago/language-strings/et';
import { strings as eu } from 'ngx-timeago/language-strings/eu';
import { strings as fa } from 'ngx-timeago/language-strings/fa';
import { strings as fi } from 'ngx-timeago/language-strings/fi';
import { strings as fr } from 'ngx-timeago/language-strings/fr';
import { strings as gl } from 'ngx-timeago/language-strings/gl';
import { strings as he } from 'ngx-timeago/language-strings/he';
import { strings as hr } from 'ngx-timeago/language-strings/hr';
import { strings as hu } from 'ngx-timeago/language-strings/hu';
import { strings as hy } from 'ngx-timeago/language-strings/hy';
import { strings as id } from 'ngx-timeago/language-strings/id';
import { strings as is } from 'ngx-timeago/language-strings/is';
import { strings as it } from 'ngx-timeago/language-strings/it';
import { strings as ja } from 'ngx-timeago/language-strings/ja';
import { strings as jv } from 'ngx-timeago/language-strings/jv';
import { strings as ko } from 'ngx-timeago/language-strings/ko';
import { strings as ky } from 'ngx-timeago/language-strings/ky';
import { strings as lt } from 'ngx-timeago/language-strings/lt';
import { strings as lv } from 'ngx-timeago/language-strings/lv';
import { strings as mk } from 'ngx-timeago/language-strings/mk';
import { strings as nl } from 'ngx-timeago/language-strings/nl';
import { strings as no } from 'ngx-timeago/language-strings/no';
import { strings as pl } from 'ngx-timeago/language-strings/pl';
import { strings as pt } from 'ngx-timeago/language-strings/pt';
import { strings as ro } from 'ngx-timeago/language-strings/ro';
import { strings as rs } from 'ngx-timeago/language-strings/rs';
import { strings as ru } from 'ngx-timeago/language-strings/ru';
import { strings as rw } from 'ngx-timeago/language-strings/rw';
import { strings as si } from 'ngx-timeago/language-strings/si';
import { strings as sk } from 'ngx-timeago/language-strings/sk';
import { strings as sl } from 'ngx-timeago/language-strings/sl';
import { strings as sr } from 'ngx-timeago/language-strings/sr';
import { strings as sv } from 'ngx-timeago/language-strings/sv';
import { strings as th } from 'ngx-timeago/language-strings/th';
import { strings as tr } from 'ngx-timeago/language-strings/tr';
import { strings as uk } from 'ngx-timeago/language-strings/uk';
import { strings as uz } from 'ngx-timeago/language-strings/uz';
import { strings as vi } from 'ngx-timeago/language-strings/vi';
import { strings as zhcn } from 'ngx-timeago/language-strings/zh-CN';
import { strings as zhtw } from 'ngx-timeago/language-strings/zh-TW';

import { TimeAgoModule } from './time-ago.module';

@Component({
  selector: 'offconon-timeago',
  templateUrl: './timeago.component.html',
  styleUrls: ['./timeago.component.scss'],
  imports: [TimeagoModule, TimeAgoModule],
})
export class TimeagoComponent {
  intl = inject(TimeagoIntl);
  private translate = inject(TranslateService);

  @Input() time_data: any;
  live = true;

  constructor() {
    this.switchLang(this.translate.currentLang);
    this.translate.onLangChange.subscribe((event: any) => {
      this.switchLang(event.lang);
    });
  }

  switchLang(lang: string) {
    switch (lang) {
      case 'af': //Afrikaans
        this.intl.strings = af;
        break;
      case 'ar': //Arabic
        this.intl.strings = ar;
        break;
      case 'az': //Azerbaijani
        this.intl.strings = az;
        break;
      case 'bg': //Bulgarian
        this.intl.strings = bg;
        break;
      case 'bs': //Bosnian
        this.intl.strings = bs;
        break;
      case 'ca': //Catalan
        this.intl.strings = ca;
        break;
      case 'cs': //Czech
        this.intl.strings = cs;
        break;
      case 'cy': //Welsh
        this.intl.strings = cy;
        break;
      case 'da': //Danish
        this.intl.strings = da;
        break;
      case 'de': //German
        this.intl.strings = de;
        break;
      case 'dv': //Divehi; Dhivehi; Maldivian
        this.intl.strings = dv;
        break;
      case 'el': //Greek
        this.intl.strings = el;
        break;
      case 'en': //English
        this.intl.strings = en;
        break;
      case 'es': //Spanish
        this.intl.strings = es;
        break;
      case 'et': //Estonian
        this.intl.strings = et;
        break;
      case 'eu': //Basque
        this.intl.strings = eu;
        break;
      case 'fa': //Persian
        this.intl.strings = fa;
        break;
      case 'fi': //Finnish
        this.intl.strings = fi;
        break;
      case 'fr': //French
        this.intl.strings = fr;
        break;
      case 'gl': //Galician
        this.intl.strings = gl;
        break;
      case 'he': //Hebrew
        this.intl.strings = he;
        break;
      case 'hr': //Croatian
        this.intl.strings = hr;
        break;
      case 'hu': //Hungarian
        this.intl.strings = hu;
        break;
      case 'hy': //Armenian
        this.intl.strings = hy;
        break;
      case 'id': //Indonesian
        this.intl.strings = id;
        break;
      case 'is': //Icelandic
        this.intl.strings = is;
        break;
      case 'it': //Italian
        this.intl.strings = it;
        break;
      case 'ja': //Japanese
        this.intl.strings = ja;
        break;
      case 'jv': //Javanese
        this.intl.strings = jv;
        break;
      case 'ko': //Korean
        this.intl.strings = ko;
        break;
      case 'ky': //Kirghiz; Kyrgyz
        this.intl.strings = ky;
        break;
      case 'lt': //Lithuanian
        this.intl.strings = lt;
        break;
      case 'lv': //Latvian
        this.intl.strings = lv;
        break;
      case 'mk': //Macedonian
        this.intl.strings = mk;
        break;
      case 'nl': //Dutch; Flemish
        this.intl.strings = nl;
        break;
      case 'no': //Norwegian
        this.intl.strings = no;
        break;
      case 'pl': //Polish
        this.intl.strings = pl;
        break;
      case 'pt': //Portuguese
        this.intl.strings = pt;
        break;
      case 'ro': //Romanian
        this.intl.strings = ro;
        break;
      case 'rs': //????
        this.intl.strings = rs;
        break;
      case 'ru': //Russian
        this.intl.strings = ru;
        break;
      case 'rw': //Kinyarwanda
        this.intl.strings = rw;
        break;
      case 'si': //	Sinhala; Sinhalese
        this.intl.strings = si;
        break;
      case 'sk': //	Slovak
        this.intl.strings = sk;
        break;
      case 'sl': //	Slovenian
        this.intl.strings = sl;
        break;
      case 'sr': //	Serbian
        this.intl.strings = sr;
        break;
      case 'sv': //	Swedish
        this.intl.strings = sv;
        break;
      case 'th': //	Thai
        this.intl.strings = th;
        break;
      case 'tr': //	Turkish
        this.intl.strings = tr;
        break;
      case 'uk': //	Ukrainian
        this.intl.strings = uk;
        break;
      case 'uz': //	Uzbek
        this.intl.strings = uz;
        break;
      case 'vi': //	Vietnamese
        this.intl.strings = vi;
        break;
      case 'zh-cn': //	Chinese - Simplified
        this.intl.strings = zhcn;
        break;
      case 'zh-tw': //	Chinese - Traditional
        this.intl.strings = zhtw;
        break;

      default:
        this.intl.strings = en;
        break;
    }
    this.intl.changes.next();
  }
}

import { NgModule, Injectable } from '@angular/core';
import { TimeagoModule, TimeagoIntl, TimeagoFormatter, TimeagoCustomFormatter } from 'ngx-timeago';

@Injectable()
export class MyIntl extends TimeagoIntl {}
@NgModule({
  declarations: [],
  imports: [
    TimeagoModule.forRoot({
      intl: { provide: TimeagoIntl, useClass: MyIntl },
      formatter: {
        provide: TimeagoFormatter,
        useClass: TimeagoCustomFormatter,
      },
    }),
  ],
})
export class TimeAgoModule {}
